@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .inline-editor-input {
    background-color: inherit;

    &:focus {
      // !important used to override hover border color.
      border: 2px solid mat.get-theme-color($theme, primary) !important;
    }

    &:hover {
      padding: 2px 1px 2px 4px;
      border: 2px solid mat.get-theme-color($theme, surface-dim);
    }
  }
}

@mixin typography($theme) {
  .inline-editor-input {
    font: mat.get-theme-typography($theme, body-medium, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}