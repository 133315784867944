@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .job-selector-section {
    background-color: mat.get-theme-color($theme, on-surface);
  }

  .add-point-button {
    background-color: mat.get-theme-color($theme, on-surface);
  }

  .add-point-button:hover {
    background-color: mat.get-theme-color($theme, primary);
  }
}

@mixin theme($theme) {
  @include color($theme);
}
