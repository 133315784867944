@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .properties-header {
    color: mat.get-theme-color($theme, on-surface);
  }
}

@mixin typography($theme) {
  .properties-header {
    font: mat.get-theme-typography($theme, headline-small, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
