@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  .edit-option {
    .drag-icon {
      color: mat.get-theme-color($theme, on-surface-variant);
    }
  }
}

@mixin theme($theme) {
  @include color($theme);
}
