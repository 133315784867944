@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .expandable-node-start {
    color: mat.get-theme-color($theme, on-surface-variant);

    .job-name {
      color: mat.get-theme-color($theme, on-surface-variant);
    }
  }

  .tree-node-selected {
    background-color: rgba(mat.get-theme-color($theme, on-surface), 0.12);

    .select-loi {
      color: mat.get-theme-color($theme, on-secondary-container);
    }
  }

  .mat-tree {
    --mat-tree-container-background-color: #{mat.get-theme-color($theme, surface-container)};

    .mat-mdc-unelevated-button {
      background-color: mat.get-theme-color($theme, surface-container);
    }

    .loi-tree-node{
      color: mat.get-theme-color($theme, on-surface-variant);
      &:hover {
        color: mat.get-theme-color($theme, on-secondary-container);
        background-color: rgba(mat.get-theme-color($theme, on-surface), 0.12);
      }
    }
  }
}

@mixin typography($theme) {
  .expandable-node-start, .loi-name {
    font: mat.get-theme-typography($theme, label-large, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}