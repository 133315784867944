/**
 * Copyright 2023 The Ground Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .page {
    background-color: mat.get-theme-color($theme, surface-container);
  }

  .card {
    background-color: mat.get-theme-color($theme, surface);
    
    .mdc-linear-progress__buffer-bar {
      background-color: mat.get-theme-color($theme, surface-container-highest);
    }

    .progress-bar-title, .description {
      color: mat.get-theme-color($theme, on-surface-variant);
    }

    .title {
      color: mat.get-theme-color($theme, on-surface);
    }
  }
}

@mixin typography($theme) {
  .progress-bar-title {
    font: mat.get-theme-typography($theme, title-small, font);
  }

  .title {
    font: mat.get-theme-typography($theme, headline-medium, font);

    div {
      font: mat.get-theme-typography($theme, label-large, font);
    }
  }

  .description {
    font: mat.get-theme-typography($theme, body-large, font);
  }

  .loading-label {
    font: mat.get-theme-typography($theme, body-large, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
