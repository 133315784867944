@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .ground-title {
    color: mat.get-theme-color($theme, on-surface);
  }

  .header-divider {
    border-left: 1px solid mat.get-theme-color($theme, outline-variant);
  }

  .header .cancel-edit-button {
    --mdc-text-button-label-text-color: #{mat.get-theme-color($theme, secondary)};
  }

  .header .finish-edit-button {
    --mdc-filled-button-container-color: #{mat.get-theme-color($theme, tertiary)};
    --mdc-filled-button-label-text-color: #{mat.get-theme-color($theme, on-primary)};
  }
}

@mixin theme($theme) {
  @include color($theme);
}
