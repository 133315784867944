@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .job-name {
    color: mat.get-theme-color($theme, on-secondary);
  }
}

@mixin theme($theme) {
  @include color($theme);
}
