@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $dialog-color: mat.get-theme-color($theme, primary);

  .reposition-confirm-dialog {
    background: $dialog-color;
  }

  .dialog-text {
    color: mat.get-theme-color($theme, on-primary);
  }

  .dialog-button {
    background: $dialog-color;
    border: 1px solid mat.get-theme-color($theme, outline);
  }
}

@mixin typography($theme) {
  .dialog-text {
    font: mat.get-theme-typography($theme, body-medium, font);
  }

  .dialog-button {
    font: mat.get-theme-typography($theme, body-small, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
