@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .vertical-divider {
    border-left: 1px solid mat.get-theme-color($theme, outline);
  }

  .question-container {
    border: 1px mat.get-theme-color($theme, outline);
  }

  .horizontal-line {
    border: 1px solid mat.get-theme-color($theme, outline);
  }
}

@mixin theme($theme) {
  @include color($theme);
}
