@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, foreground);

  .submission {
    .task-label {
      color: mat.get-theme-color($theme, on-secondary);
    }

    .task-result {
      color: mat.get-theme-color($theme, on-primary);
    }
  }

  .no-submission-text {
    color: mat.get-theme-color($theme, on-secondary);
  }
}

@mixin typography($theme) {
  .submission {
    .task-label {
      font: mat.get-theme-typography($theme, body-small, font);
    }
    .task-result {
      font: mat.get-theme-typography($theme, body-medium, font);
    }
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
