@use 'sass:map';
@use '@angular/material' as mat;

@mixin typography($theme) {
  .share-dialog-description {
    font: mat.get-theme-typography($theme, body-medium, font);
  }
}

@mixin theme($theme) {
  @include typography($theme);
}
