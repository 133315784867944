@use 'sass:map';
@use '@angular/material' as mat;

@mixin typography($theme) {
  .body-container {
    .title {
      font: mat.get-theme-typography($theme, headline-medium, font);
    }
  
    .content {
      font: mat.get-theme-typography($theme, body-large, font);
      text-align: center;
      padding: 40px;
    }
  }
}

@mixin theme($theme) {
  @include typography($theme);
}
