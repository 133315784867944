@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .link-add-question {
    color: mat.get-theme-color($theme, primary);
  }

  .add-question {
    border: 1px solid mat.get-theme-color($theme, outline) !important;
  }

  .horizontal-line {
    border: 1px solid mat.get-theme-color($theme, outline);
  }

  .label-helper-text {
    color: mat.get-theme-color($theme, on-secondary);
  }
}

@mixin typography($theme) {
  .mat-mdc-dialog-content {
    font-family: mat.get-theme-typography($theme, body-medium, font-family);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
