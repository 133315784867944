@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .user-default-thumb {
    background: mat.get-theme-color($theme, primary);
    color: mat.get-theme-color($theme, on-primary);
  }
}

@mixin theme($theme) {
  @include color($theme);
}
