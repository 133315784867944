@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  div[left] {
    .survey-title {
      color: mat.get-theme-color($theme, on-surface-variant);
    }
  }
}

@mixin theme($theme) {
  @include color($theme);
}
