@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .page {
    background-color: mat.get-theme-color($theme, surface-container);
  }

  .mat-mdc-card {
    &.survey-card {
      background-color: mat.get-theme-color($theme, surface);

      &:hover {
        background-color: mat.get-theme-color($theme, surface-variant);
      }
    }

    &.new-survey-card {
      background-color: mat.get-theme-color($theme, surface-variant);
    }

    .add-icon-container {
      background-color: mat.get-theme-color($theme, primary-container);
    }
  
    .survey-title {
      color: mat.get-theme-color($theme, on-surface);
    }

    .survey-description {
      color: mat.get-theme-color($theme, outline);
    }
  }
}

@mixin typography($theme) {
  .page-title {
    font: mat.get-theme-typography($theme, display-medium, font);
  }

  .survey-title {
    font: mat.get-theme-typography($theme, headline-small, font);
  }

  .survey-description {
    font: mat.get-theme-typography($theme, body-medium, font);
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
