@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .create-survey-btn {
    color: mat.get-theme-color($theme, on-primary);
    background-color: mat.get-theme-color($theme, primary-container);
  }
}

@mixin theme($theme) {
  @include color($theme);
}
