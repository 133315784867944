/**
 * Copyright 2024 The Ground Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	.loi-editor .loi-editor-card .loi-editor-card-row button.import-lois-button {
		--mdc-filled-button-container-color: #FFDDAB;
		--mdc-filled-button-label-text-color: mat.get-theme-color($theme, on-secondary-container);
  }
}

@mixin typography($theme) {
	.loi-editor-card-row-title {
		font: mat.get-theme-typography($theme, title-medium, font);
	}
	.loi-editor-card-row-subtitle {
		font: mat.get-theme-typography($theme, body-medium, font);
	}
}

@mixin theme($theme) {
	@include color($theme);
	@include typography($theme);
}